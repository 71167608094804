const PERSONAL_DOMAINS = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "protonmail.com",
  "zoho.com",
  "live.com",
  "yandex.com",
  "rediffmail.com",
  "facebook.com",
  "twitter.com",
  "linkedin.com",
  "gmx.de", "mail.ru", "web.de", "libero.it", "qq.com", "sina.com", "hanmail.net", "naver.com",
  "icloud.com", "me.com", "fastmail.com", "hushmail.com", "posteo.net", "tutanota.com",
  "edu.com", ".edu", "ac.in", ".ac.uk", "posteo.net", "tutanota.com"
];
const TEMP_DOMAINS = [
  "yopmail.com",
  "tempmail.com",
  "mailinator.com",
  "10minutemail.com",
  "dispostable.com",
  "guerrillamail.com", "temp-mail.org", "throwawaymail.com", "maildrop.cc", 
  "fakemail.net", "trashmail.com", "getnada.com", "spambog.com", "moakt.com", "mintemail.com", 
  "qq.com", "sina.com", "hanmail.net", "naver.com"
];

// Regex for basic email validation
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const bookNowClick = () =>
  {

    if(sessionStorage.getItem("journey")==="old")
    {
      window.location.assign('https://accounts.hindustantimes.com/buy-ht-newspaper-subscription?utm_source=htreaders&utm_medium=new_customer');
    }
    else
    {
      window.location.assign('https://accounts.hindustantimes.com/buy-ht-newspaper-subscription?utm_source=htreaders&utm_medium=new_customer');
      
    }
  }

  export const registrationGetCityName = (e) => 
  {
    if (e.toLowerCase() == "cpm") return 'Chandigarh';
    else if (e.toLowerCase() == "delhi") return 'Delhi NCR';
    else return e;
  }

  export const registrationGetCityNameOrder = (e) => 
  {
    if (e?.toLowerCase() == "cpm") return 'Chandigarh';
    else return e;
  }

  export const getUtmSource = ()=>
    {
      const url_string = window.location.href
      const url = new URL(url_string);
      let utm_source = "";
      let utm_medium = "";
      let utm_name = "";
      if(url_string.includes("utm"))
      {
        utm_source = url.searchParams.get("utm_source") || "";
        utm_medium = url.searchParams.get("utm_medium") || "";
        utm_name = url.searchParams.get("utm_campaign") || "";
      }
      sessionStorage.setItem("stg_utm_source",utm_source);
      sessionStorage.setItem("stg_utm_medium",utm_medium);
      sessionStorage.setItem("stg_utm_name",utm_name);
  }

  export const setUtmSource = (removeKey = "") => {
    let params = new URLSearchParams();

    // UTM parameters mapping
    const utmKeys = {
        utm_source: "stg_utm_source",
        utm_medium: "stg_utm_medium",
        utm_campaign: "stg_utm_name",
    };

    // Add UTM parameters from sessionStorage if they exist
    Object.entries(utmKeys).forEach(([param, storageKey]) => {
        const value = sessionStorage.getItem(storageKey);
        if (value) params.set(param, value);
    });

    // Remove the specified key if needed
    if (removeKey) params.delete(removeKey);

    // Construct the final URL parameters
    return params.toString() ? `&${params.toString()}` : "";
};

  export const bookNowClickBanner = () => {
    window.location.assign('https://accounts.hindustantimes.com/ht-hindustan-mint-newspaper-epaper-subscription?utm_source=htreaders&utm_medium=inpage_banner');
  }

  export const nonLoginUserRedirection = () =>
  {
    if(sessionStorage.getItem("journey")==="old")
    {
      window.location.assign('https://accounts.hindustantimes.com/buy-ht-newspaper-subscription?utm_source=htreaders&utm_medium=redirection');
    }
    else
    {
      window.location.assign('https://accounts.hindustantimes.com/buy-ht-newspaper-subscription?utm_source=htreaders&utm_medium=redirection');
    }

  }
  export const buyNowLandingPage = () => 
  {
    window.location.assign('https://www.htreaders.com/?msg=newCustomer&utm_source=banner');
  }

  export const refreshPage = () => {
    window.location.reload(true);
  };


  export async function getDataKeySame(url = "") {

    try {
      const USER_API_URL = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('preAuthAllSchemeToken');
      let response = await fetch(USER_API_URL +'/'+url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+token, 
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer" // no-referrer, *client,
      });
  
      response = await response.json(); // parses JSON response into native JavaScript objects
  
      if (response && response.status === 200) 
      {
         return response;
      } 
      else
      {
         return response;
      }
    } catch (e) {
      console.log(e);
    }
  }

  export const validateCorporateEmail = async (email) => {

    if (!email) {
      return "Email is required.";
    }

    // Check format
    if (!emailRegex.test(email)) {
      return "Invalid email format.";
    }

    // Extract domain
    const domain = email.split("@")[1];

    // Check if domain is in personal or temp lists
    if (PERSONAL_DOMAINS.includes(domain)) {
      return "Please enter your corporate email Id.";
    }
    if (TEMP_DOMAINS.includes(domain)) {
      return "Please enter your corporate email Id.";
    }

    // Ensure it's a corporate/official domain
    const domainParts = domain.split(".");
    if (domainParts.length < 2 || domainParts[0].length < 2) {
      return "Invalid corporate email domain.";
    }

    return "";
  };

  export const GTM_data_push = (data) => {
    if (!data || typeof data !== "object") return;
  
    window.dataLayer = window.dataLayer || [];
  
    const { eventName, ...rest } = data;
  
    window.dataLayer.push({
      event: eventName,
      ...rest,
    });
  };
  